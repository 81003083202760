import * as types from './constants';

export const setLoadStatus = bool => ({
  type: types.LOAD_STATUS,
  payload: bool,
});

export const setUserToken = token => ({
  type: types.SET_USER_TOKEN,
  payload: token,
});

export const validateUser = payload => ({
  type: types.VALIDATE_USER,
  payload,
});

export const setCurrentUser = userObj => ({
  type: types.SET_USER,
  payload: userObj,
});

export const showSidebar = bool => ({
  type: types.SHOW_SIDEBAR,
  payload: bool,
});

/* eslint-disable react/prop-types */
import { mapValues } from 'lodash';
import { Controller } from 'react-hook-form';

import Button from 'components/Button';
import Field from './Field';
import { FormFooter } from './styles';

const Form = ({ onSubmit, ...otherProps }) => (
  <form onSubmit={onSubmit} {...otherProps} />
);

const renderField =
  FieldComponent =>
  ({ control, name, defaultValue, ...props }) =>
    (
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => <FieldComponent {...props} {...field} />}
      />
    );

Form.Field = mapValues(Field, renderField);

Form.Footer = ({
  loadStatus,
  onCancel,
  submitBtnText,
  onSubmit,
  submitType = 'submit',
  footerStyles,
}) => (
  <FormFooter style={footerStyles}>
    <Button
      isWorking={loadStatus}
      type={submitType}
      variant="primary"
      onClick={onSubmit}
    >
      {submitBtnText}
    </Button>

    {onCancel && (
      <Button onClick={onCancel} variant="empty">
        Cancel
      </Button>
    )}
  </FormFooter>
);

export default Form;

import styled from 'styled-components';
import { color, mixin } from 'shared/utils/styles';

export const TableWrapper = styled.div`
  background: ${color.secondary};
  padding: 10px;
  width: 100%;
  overflow: scroll;
  height:auto;
`;

export const StyledTable = styled.table`
  background: #ffffff;
  ${mixin.boxShadowDropdown};
  padding: 10px;
  width: 100%;
  overflow: scroll;
  height:auto;
`;

export const StyledRow = styled.tr`
  background: ${props => (props.isActive ? color.backgroundLight : 'none')};
`;

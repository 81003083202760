import { createSelector } from 'reselect';
import { initialState } from './reducer';

const submissions = state => state.submissions || initialState;

export const getLoadStatus = () =>
  createSelector(submissions, store => store.loadStatus);

export const getSubmissionsCount = () =>
  createSelector(submissions, store => store.totalSubmissions);

export const getSubmissions = () =>
  createSelector(submissions, store => store.submissions);

export const getItemsPerPage = () =>
  createSelector(submissions, store => store.itemsPerPage);

import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding: 200px 0;
  text-align: center;

  h3 {
    margin-bottom: 10px;
  }
`;

import styled from 'styled-components';
import { color, font, mixin } from 'shared/utils/styles';

export const StyledHead = styled.thead`
  // background-color: lightblue;
`;

export const StyledTableHead = styled.th`
  text-align: ${props => props.align || 'left'};
  padding: 10px;
  text-transform: uppercase;
  background: 'gray';
  color: ${color.textDark};
  ${font.bold};
  ${font.size(14)};
  ${mixin.clickable};
`;

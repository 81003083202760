import { applyMiddleware, compose, createStore } from 'redux';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';

const configureStore = (initialState = {}) => {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const sagaMiddleware = createSagaMiddleware();
  const runSaga = sagaMiddleware.run;

  const injectorEnhancer = createInjectorsEnhancer({
    createReducer,
    runSaga,
  });

  // const customMiddleware = store => next => action => {
  //   next(action);
  // };

  const middlewares = [sagaMiddleware];
  const enhancers = [applyMiddleware(...middlewares), injectorEnhancer];

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );

  return store;
};

export default configureStore;

import PropTypes from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs';
import { ContentWrapper, HeaderWrapper } from './styles';

const headerPropTypes = {
  title: PropTypes.string,
};

const LayoutHeader = ({ title }) => (
  <HeaderWrapper>
    <h2>{title}</h2>
  </HeaderWrapper>
);

const propTypes = {
  breadCrumbs: PropTypes.array,
  children: PropTypes.any,
  createBtnText: PropTypes.string,
  createPath: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
};

const defaultProps = {
  breadCrumbs: [],
  createPath: null,
};

const Layout = ({
  breadCrumbs,
  children,
  createPath,
  createBtnText,
  pageTitle,
}) => (
  <>
    <LayoutHeader
      btnText={createBtnText}
      createPath={createPath}
      title={pageTitle}
    />
    <Breadcrumbs items={['AIS', ...breadCrumbs]} />
    <ContentWrapper>{children}</ContentWrapper>
  </>
);

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;
LayoutHeader.propTypes = headerPropTypes;

export default Layout;

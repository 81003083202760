import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  size: PropTypes.number,
};

const defaultProps = {
  className: '',
  size: 24,
};

const Icon = ({ className, icon, size, ...props }) => (
  <StyledIcon
    className={className}
    size={size}
    src={icon}
    wrapper="span"
    {...props}
  />
);

export default Icon;
Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

const StyledIcon = styled(ReactSVG)`
  height: ${props => props.size}px;

  span {
    height: ${props => props.size}px;
  }

  svg {
    height: ${props => props.size}px;
    width: ${props => props.size}px;
  }
`;

import styled from 'styled-components';
import { color, sizes, zIndexValues } from 'shared/utils/styles';

export const SidebarWrapper = styled.div`
  background: ${color.secondary};
  border-right: 1px solid ${color.backgroundMedium};
  height: 100vh;
  left: ${sizes.appNavBarLeftWidth}px;
  overflow: hidden auto;
  padding: 0 16px 24px;
  position: fixed;
  top: 0;
  transition: 0.25s ease;
  width: ${sizes.secondarySideBarWidth}px;
  z-index: ${zIndexValues.navLeft - 1};

  @media (max-width: 1000px) {
    left: ${props =>
      props.showSidebar ? `${sizes.appNavBarLeftWidth}px` : '-100%'};
  }
`;

export const Header = styled.div`
  // height: 93px; // temporary, remove once content has been added
  display: flex;
  justify-content: center;
  padding: 24px 4px;
  margin-bottom: 25px;

  .logo {
    & * {
      width: 150px;
    }
  }
`;

export const Seperator = styled.div`
  margin-top: 17px;
  padding-top: 18px;
  border-top: 1px solid ${color.borderLight};
`;

import { Route, useHistory } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useSelector } from 'react-redux';

import { SubmissionReducer as reducer } from 'store/Submission/reducer';
import saga from 'store/Submission/sagas';
import {
  getLoadStatus,
  getSubmissions,
  getSubmissionsCount,
} from 'store/Submission/selectors';

import Modal from 'components/Modal';
import List from './List';
import SubmissionDetails from './SubmissionDetails';

const SubmissionsPage = () => {
  useInjectReducer({ key: 'submissions', reducer });
  useInjectSaga({ key: 'submissions', saga });

  const history = useHistory();
  const loadStatus = useSelector(getLoadStatus());
  const totalSubmissions = useSelector(getSubmissionsCount());
  const submissions = useSelector(getSubmissions());

  const getSubmissionDetails = ({ submissionId }) =>
    submissions.find(r => r._id === submissionId);

  const goToList = () => {
    history.push('/submissions');
  };

  return (
    <>
      <List
        data={submissions}
        loadStatus={loadStatus}
        totalSubmissions={totalSubmissions}
      />
      <Route
        exact
        path="/submissions/:submissionId"
        render={routeProps => (
          <Modal
            isOpen
            onClose={goToList}
            width={1024}
            withCloseIcon={false}
            renderContent={modal => (
              <SubmissionDetails
                data={getSubmissionDetails(routeProps.match.params)}
                modalClose={modal.close}
                loadStatus={loadStatus}
                {...routeProps}
              />
            )}
          />
        )}
      />
    </>
  );
};

export default SubmissionsPage;

import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ConfirmModal from 'components/ConfirmModal';

import TrashIcon from 'static/icons/trash.svg';

const propTypes = {
  onDelete: PropTypes.func.isRequired,
  iconSize: PropTypes.number,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

const defaultProps = {
  iconSize: 19,
  variant: 'empty',
};

const Delete = ({ type, iconSize, onDelete, variant }) => {
  const handleConfirm = props => {
    onDelete(props);
  };

  return (
    <ConfirmModal
      title={`Are you sure you want to delete this ${type}?`}
      message=""
      confirmText={`Delete ${type}`}
      onConfirm={handleConfirm}
      renderLink={modal => (
        <Button
          icon={TrashIcon}
          iconSize={iconSize}
          variant={variant}
          onClick={modal.open}
          type="button"
          title="Delete"
        />
      )}
    />
  );
};

Delete.propTypes = propTypes;
Delete.defaultProps = defaultProps;

export default Delete;

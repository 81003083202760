import produce from 'immer';
import * as types from './constants';

export const initialState = {
  totalSubmissions: 0,
  itemsPerPage: 20,
  submissions: [],
  loadStatus: true,
};

export const SubmissionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOAD_STATUS:
        draft.loadStatus = action.payload;
        break;

      case types.RECEIVED_SUBMISSIONS_COUNT:
        draft.totalSubmissions = action.payload;
        break;

      case types.FETCH_SUBMISSIONS:
        draft.loadStatus = true;
        break;

      case types.RECEIVED_SUBMISSIONS:
        draft.submissions = action.payload;
        break;

      case types.SET_ITEMS_PER_PAGE:
        draft.itemsPerPage = action.payload;
        break;

      case types.DELETED_SUBMISSION:
        draft.submissions = draft.submissions.filter(
          r => !action.payload.includes(r._id),
        );
        break;
    }
  });

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';
import Button from 'components/Button'
import { nanoid } from 'nanoid';

import request from 'shared/utils/request';

import {
  deleteSubmission,
  fetchSubmissions,
  setItemsPerPage,
  triggerFailedMail,
} from 'store/Submission/actions';
import { getItemsPerPage } from 'store/Submission/selectors';

import Layout from 'containers/Layout/Route';
import EmptyPage from 'components/EmptyPage';
import Pagination from 'components/Pagination';
import Select from 'components/Select';
import toast from 'components/toast';

import DataTable from '../DataTable';

const tableHead = [
  { id: 'name', label: 'NAME' },
  { id: 'email', label: 'EMAIL' },
  { id: 'date', label: 'DATE' },
  { id: 'time', label: 'TIME' },
];

const defaultPaginations = {
  page: 0,
  perPage: 20,
};

const getImage = async ({ filename }) => {
  const reqUrl = `${APP_CONFIG.SERVER_URL}/server/static/${filename}`;
  const imageBuffer = await request(reqUrl, { responseType: 'arraybuffer' });

  const parts = filename.split(/\./);

  return {
    ext: parts[parts.length - 1],
    data: Buffer.from(imageBuffer, 'binary').toString('base64'),
  };
};

const List = ({ data, loadStatus, totalSubmissions }) => {
  const dispatch = useDispatch();
  const itemsPerPage = useSelector(getItemsPerPage());
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginations, setPaginations] = useState(defaultPaginations);

  const handleFetchSubmissions = () => {
    const queries = { ...paginations };
    dispatch(fetchSubmissions(queries));

    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  };

  useEffect(() => {
    setPaginations(prev => ({ ...prev, page: 0, perPage: itemsPerPage }));
    setSelectedRows([]);
  }, [itemsPerPage]);

  useEffect(handleFetchSubmissions, [paginations]);

  const pages = useMemo(
    () =>
      Pagination.getRange(Math.ceil(totalSubmissions / paginations.perPage)),
    [totalSubmissions, paginations],
  );

  const handleAllRowsSelect = () => {
    if (selectedRows.length === 0 || selectedRows.length !== data.length) {
      setSelectedRows(data.map(td => td._id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = rowId => {
    if (selectedRows.includes(rowId)) {
      const filteredRows = selectedRows.filter(id => id !== rowId);
      setSelectedRows(filteredRows);
    } else {
      setSelectedRows(prev => [...prev, rowId]);
    }
  };

  const handlePageChange = (_e, page) => {
    setPaginations(prev => ({ ...prev, page: page - 1 }));
    setSelectedRows([]);
  };

  const handleItemPerPageChange = newValue => {
    dispatch(setItemsPerPage(newValue));
    setSelectedRows([]);
  };

  const downloadFiles = async submissionId => {
    const currentSubmission = data.find(r => r._id === submissionId);
    const zip = new JSZip();

    const addMediaToZip = ({ ext, data: base64 }) => {
      zip.file(`${nanoid(10)}-${Date.now()}.${ext}`, base64, {
        base64: true,
      });
    };

    const allRes = await Promise.all(currentSubmission.files.map(getImage));
    allRes.forEach(addMediaToZip);

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, `${submissionId}.zip`);
    });
  };

  const downloadMultipleFiles = async submissionIds => {
    if (submissionIds.length === 0) {
      toast.error('No orders found!');
      return;
    }
    submissionIds.forEach(downloadFiles);
  };

  const triggerMail = submissionId => {
    dispatch(triggerFailedMail(submissionId));
  };

  const handleDeleteSubmission = submissionId => {
    dispatch(deleteSubmission(submissionId));
    setSelectedRows([]);
  };

  const handleView = async submissionId => {
    const currentSubmission = data.find(r => r._id === submissionId);
    try {
      currentSubmission.files.forEach(file => {
        window.open(`${APP_CONFIG.SERVER_URL}/server/static/${file.filename}`);
      });
    } catch (ex) {
      console.error(ex.submission?.data || ex.message);
    }
  };

  return (
    <Layout
      breadCrumbs={['Form Submissions']}
      load
      pageTitle="Timesheet Submissions"
    >
      <Button onClick={handleFetchSubmissions} style={{float: 'right', margin: 10,}}>Refresh</Button>
      {data.length === 0 && (
        <EmptyPage loadStatus={loadStatus} showBtn={false} />
      )}

      {data.length > 0 && (
        <>
          <DataTable
            containerStyles={{ marginTop: '25px', overflow: 'scroll', scrollY:true }}
            onAllRowsSelect={handleAllRowsSelect}
            onRowSelect={handleRowSelect}
            selectedRows={selectedRows}
            tableHead={tableHead}
            tableData={data}
            onDownload={downloadFiles}
            onDelete={handleDeleteSubmission}
            onView={handleView}
            onMultiDownload={downloadMultipleFiles}
            onTriggerMail={triggerMail}
          />

          {pages.length > 0 && (
            <div className="pagination-wrapper">
              <Pagination
                currentIndex={paginations.page}
                onChange={handlePageChange}
                pages={pages}
              />

              <Select
                className="page-select"
                dropdownWidth={100}
                onChange={handleItemPerPageChange}
                options={[
                  { label: '20', value: 20 },
                  { label: '50', value: 50 },
                  { label: '75', value: 75 },
                ]}
                value={paginations.perPage}
                withClearValue={false}
              />
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  loadStatus: PropTypes.bool.isRequired,
  totalSubmissions: PropTypes.number.isRequired,
};

export default List;

import axios from 'axios';

axios.interceptors.request.use(config => {
  if (config.submissionType === 'arraybuffer') {
    // eslint-disable-next-line no-param-reassign
    delete config.headers.common.Authorization;
  }

  return config;
});

const baseURL = `${APP_CONFIG.SERVER_URL}/server/admin`;

const request = async (url, options = {}) => {
  const { data } = await axios({ baseURL, url, ...options });
  return data;
};

export default request;

import PropTypes from 'prop-types';

import TableHead from './TableHead';
import TableBody from './TableBody';
import { StyledTable, TableWrapper } from './styles';
import Toolbar from './Toolbar';

const DataTable = props => {
  const {
    containerStyles,
    onAllRowsSelect,
    onRowSelect,
    selectedRows,
    tableHead,
    tableData,
    onTriggerMail,
    onDownload,
    onDelete,
    onView,
    onMultiDownload,
  } = props;

  return (
    <TableWrapper style={containerStyles}>
      <Toolbar
        onDelete={onDelete}
        onDownload={onMultiDownload}
        selected={selectedRows}
      />

      <StyledTable cellSpacing="0">
        <TableHead
          data={tableHead}
          onSelectAll={onAllRowsSelect}
          selectedCount={selectedRows.length}
          isAllSelected={tableData.length === selectedRows.length}
          isNoneSelected={selectedRows.length === 0}
        />

        <TableBody
          data={tableData}
          onDownload={onDownload}
          onDelete={onDelete}
          onView={onView}
          onSelect={onRowSelect}
          selected={selectedRows}
          onTriggerMail={onTriggerMail}
        />
      </StyledTable>
    </TableWrapper>
  );
};

DataTable.propTypes = {
  containerStyles: PropTypes.object,
  onAllRowsSelect: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onMultiDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  tableHead: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onTriggerMail: PropTypes.func.isRequired,
};
export default DataTable;

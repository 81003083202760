import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Navbar from 'components/nav/Navbar';
import Sidebar from 'components/nav/Sidebar';

import Auth from 'containers/Auth';
import ResetPassword from 'containers/Auth/reset-password';
import Submissions from 'containers/Submissions';
import PageLoader from 'components/PageLoader';

const Routes = ({ loadStatus, currentUser }) => {
  if (loadStatus) return <PageLoader />;

  if (!loadStatus && !currentUser) {
    return (
      <Switch>
        <Route exact path="/auth/signin" component={Auth} />
        <Route exact path="/auth/reset" component={ResetPassword} />
        <Redirect to="/auth/signin" />
      </Switch>
    );
  }

  return (
    <>
      <Navbar />
      <Sidebar />

      <main>
        <Switch>
          <Route path="/submissions" component={Submissions} />
          <Redirect from="/" to="/submissions" />
        </Switch>
      </main>
    </>
  );
};

Routes.propTypes = {
  loadStatus: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default Routes;

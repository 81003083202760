import Pagination from '@atlaskit/pagination';
import PropTypes from 'prop-types';

const propTypes = {
  currentIndex: PropTypes.number,
  onChange: PropTypes.func,
  pages: PropTypes.array.isRequired,
};

const PaginationComp = ({ currentIndex, pages, onChange }) => (
  <Pagination selectedIndex={currentIndex} onChange={onChange} pages={pages} />
);

PaginationComp.getRange = max => new Array(max).fill(0).map((_v, i) => i + 1);

PaginationComp.propTypes = propTypes;

export default PaginationComp;

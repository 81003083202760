import PropTypes from 'prop-types';

import Button from 'components/Button';

import CloseIcon from 'static/icons/close.svg';
import { HeaderWrapper, HeaderActions } from './styles';

const Header = ({ modalClose, title }) => (
  <HeaderWrapper>
    <h4>{title}</h4>

    <HeaderActions>
      {modalClose && (
        <Button
          icon={CloseIcon}
          iconSize={22}
          onClick={modalClose}
          variant="empty"
        />
      )}
    </HeaderActions>
  </HeaderWrapper>
);

Header.propTypes = {
  modalClose: PropTypes.func,
  title: PropTypes.string,
};

export default Header;

import * as types from './constants';

export const setLoadStatus = bool => ({
  type: types.LOAD_STATUS,
  payload: bool,
});

export const receivedSubmissionsCount = count => ({
  type: types.RECEIVED_SUBMISSIONS_COUNT,
  payload: count,
});

export const fetchSubmissions = query => ({
  type: types.FETCH_SUBMISSIONS,
  payload: query,
});

export const receivedSubmissions = submissions => ({
  type: types.RECEIVED_SUBMISSIONS,
  payload: submissions,
});

export const setItemsPerPage = page => ({
  type: types.SET_ITEMS_PER_PAGE,
  payload: page,
});

export const triggerFailedMail = submissionId => ({
  type: types.TRIGGER_MAIL,
  payload: submissionId,
});

export const triggeredMail = submissionId => ({
  type: types.TRIGGERED_MAIL,
  payload: submissionId,
});

export const deleteSubmission = submissionId => ({
  type: types.DELETE_SUBMISSION,
  payload: submissionId,
});

export const deletedSubmission = submissionId => ({
  type: types.DELETED_SUBMISSION,
  payload: submissionId,
});

import styled from 'styled-components';
import { color, font, mixin } from 'shared/utils/styles';

export const StyledBody = styled.tbody`
  // background-color: lightblue;
`;

export const StyledTableItem = styled.td`
  border-bottom: 1px solid ${color.borderLight};
  color: ${color.textDarkest};
  padding: ${props => props.padding || '20px 10px'};
  text-align: ${props => props.align || 'left'};

  ${font.regular};
  ${font.size(14)};
  ${mixin.clickable};

  .light-text {
    color: ${color.textMedium};
  }
`;

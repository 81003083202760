import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";

import { auth } from "configs/firebase";
import toast from "components/toast";

import { getShowSidebar } from "store/App/selectors";

import DashboardIcon from "static/icons/dashboard.svg";
import LogoutIcon from "static/icons/logout.svg";
import Logo from "static/icons/logo.svg";

import ListItem from "./ListItem";
import { SidebarWrapper, Header, Seperator } from "./styles";

const logout = async () => {
  try {
    await auth.signOut();
    window.location.href = "/auth/signin";
  } catch (ex) {}
};

const menus = [
  {
    id: 1,
    name: "Form submissions",
    path: "/submissions",
    icon: DashboardIcon,
  },
  { id: "seperator-1" },
  {
    id: 12,
    name: "Logout",
    icon: LogoutIcon,
    path: "/",
    onClick: logout,
  },
];

const Sidebar = () => {
  const sidebarVisibility = useSelector(getShowSidebar());

  return (
    <SidebarWrapper showSidebar={sidebarVisibility}>
      <Header>
        <ReactSVG
          src={Logo}
          className="logo"
        />
      </Header>

      {menus.map((menu) =>
        Object.keys(menu).length > 1 ? (
          <ListItem key={menu.id} data={menu} />
        ) : (
          <Seperator key={menu.id} />
        )
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;

import React, { forwardRef } from 'react';

import { color } from 'shared/utils/styles';
import Icon from 'components/Icon';

import { defaultProps, propTypes } from './configs';
import { StyledButton, StyledSpinner, Text } from './styles';

const getIconColor = variant =>
  ['secondary', 'empty'].includes(variant) ? color.textDark : '#fff';

const Button = forwardRef(
  (
    {
      children,
      variant,
      icon,
      iconSize,
      disabled,
      isWorking,
      onClick,
      ...buttonProps
    },
    ref,
  ) => {
    const handleClick = e => {
      if (!disabled && !isWorking) {
        onClick(e);
      }
    };

    return (
      <StyledButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        onClick={handleClick}
        variant={variant}
        disabled={disabled || isWorking}
        isWorking={isWorking}
        iconOnly={!children}
        ref={ref}
      >
        {isWorking && <StyledSpinner size={26} color={getIconColor(variant)} />}

        {!isWorking && icon && <Icon size={iconSize} icon={icon} />}

        {children && <Text withPadding={isWorking || icon}>{children}</Text>}
      </StyledButton>
    );
  },
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;

/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import CircleIcon from 'static/icons/circle.svg';
import CircleCheckedIcon from 'static/icons/circle-checked.svg';
import CircleMinusIcon from 'static/icons/circle-minus.svg';
import { StyledRow } from '../styles';
import { StyledHead, StyledTableHead } from './styles';

const propTypes = {
  data: PropTypes.array.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  isNoneSelected: PropTypes.bool.isRequired,
};

const TableHead = ({ data, isAllSelected, isNoneSelected, onSelectAll }) => (
  <StyledHead style={{backgroundColor: '#E0DFDA'}}>
    <StyledRow onClick={onSelectAll}>
      <StyledTableHead>
        <Icon
          icon={
            isAllSelected
              ? CircleCheckedIcon
              : isNoneSelected
              ? CircleIcon
              : CircleMinusIcon
          }
          size={16}
        />
      </StyledTableHead>

      {data.map(headObj => (
        <StyledTableHead key={headObj.id}>{headObj.label}</StyledTableHead>
      ))}

      <StyledTableHead align="right">Actions</StyledTableHead>
    </StyledRow>
  </StyledHead>
);

TableHead.propTypes = propTypes;
export default TableHead;

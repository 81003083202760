import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin: 25px 0px 0px;
  overfloe:scroll;
  // margin-top: 56px; // remove once header is implemented

  .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-right: 25px;

    .page-select {
      margin-left: 25px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

import toast from 'components/toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import request from 'shared/utils/request';
import { auth } from 'configs/firebase';
import { setLoadStatus, setCurrentUser } from './actions';
import { SET_USER_TOKEN } from './constants';

function* validateUser() {
  try {
    const submission = yield call(request, '/user');
    yield put(setCurrentUser(submission));
  } catch (ex) {
    const message = ex.response?.data || ex.message;
    toast.error(message);
    auth.signOut();
  } finally {
    yield put(setLoadStatus(false));
  }
}

export default function* AppSaga() {
  yield all([takeLatest(SET_USER_TOKEN, validateUser)]);
}

import PropTypes from 'prop-types';

import UpdateLayout from 'containers/Layout/Update';

const SubmissionDetails = props => {
  const { data, loadStatus, modalClose } = props;

  if (!loadStatus && !data) {
    modalClose();
    return null;
  }

  if (loadStatus) return null;

  return (
    <UpdateLayout
      modalClose={modalClose}
      title="Submission Details"
      showDelete={false}
    >
      <p>Name: {data.name}</p>
      <p>Email: {data.email}</p>
      <p>Date: {data.date}</p>
      <p>Time: {new Date(data.createdAt).toLocaleTimeString()}</p>
      <p>Total files: {data.files.length}</p>
      <br />
    </UpdateLayout>
  );
};

SubmissionDetails.propTypes = {
  data: PropTypes.object,
  modalClose: PropTypes.func,
  loadStatus: PropTypes.bool,
};

export default SubmissionDetails;

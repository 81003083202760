import PropTypes from 'prop-types';

import Button from 'components/Button';
import Delete from 'components/Delete';

import DownloadIcon from 'static/icons/download.svg';
import { StyledToolbar } from './styles';

const Toolbar = ({ onDelete, onDownload, selected }) => (
  <StyledToolbar isActive={selected.length > 1}>
    <span>{selected.length} Selected</span>

    <div className="action-btns">
      <Delete
        iconSize={20}
        onDelete={modal => {
          onDelete(selected);
          modal.close();
        }}
        type="item"
        variant="primary"
      />
      <Button
        icon={DownloadIcon}
        iconSize={16}
        style={{ marginLeft: '15px' }}
        onClick={e => {
          e.stopPropagation();
          onDownload(selected);
        }}
        variant="primary"
      >
        Download selected
      </Button>
    </div>
  </StyledToolbar>
);

Toolbar.propTypes = {
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
};

export default Toolbar;

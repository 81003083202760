import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import { NotImplemented, StyledItem } from './styles';

const propTypes = {
  data: PropTypes.object.isRequired,
};

const ListItem = ({ data }) => (
  <StyledItem
    exact={data.path === '/'}
    implemented={data.path}
    as={data.path && NavLink}
    to={data.path}
    activeClassName="active"
    onClick={data.onClick}
  >
    <ReactSVG className="menu-icon" src={data.icon} />
    <div className="menu-name">{data.name}</div>

    {!data.path && (
      <NotImplemented className="not-implemented">
        Not implemented
      </NotImplemented>
    )}
  </StyledItem>
);

ListItem.propTypes = propTypes;

export default ListItem;

import { all, call, put, takeLatest } from 'redux-saga/effects';

import request from 'shared/utils/request';
import toast from 'components/toast';

import * as types from './constants';
import {
  setLoadStatus,
  receivedSubmissions,
  receivedSubmissionsCount,
  triggeredMail,
  deletedSubmission,
} from './actions';

function* fetchSubmissions(action) {
  try {
    const reqOptions = { params: action.payload };
    const submission = yield call(request, '/submissions', reqOptions);
    yield put(receivedSubmissions(submission.submissions));
    yield put(receivedSubmissionsCount(submission.count));
  } catch (ex) {
    const message = ex.submission?.data || ex.message;
  } finally {
    yield put(setLoadStatus(false));
  }
}

function* triggerFailedMail(action) {
  try {
    yield call(request, `/submissions/mail/${action.payload}`);
    yield put(triggeredMail(action.payload));
  } catch (ex) {
    const message = ex.submission?.data || ex.message;
    toast.error(message);
  }
}

function* deleteSubmission(action) {
  try {
    const reqOptions = {
      method: 'POST',
      data: { submissionIds: action.payload },
    };
    yield call(request, `/submissions/delete`, reqOptions);
    yield put(deletedSubmission(action.payload));
  } catch (ex) {
    const message = ex.submission?.data || ex.message;
    toast.error(message);
  }
}

export default function* SubmissionSaga() {
  yield all([
    takeLatest(types.FETCH_SUBMISSIONS, fetchSubmissions),
    takeLatest(types.TRIGGER_MAIL, triggerFailedMail),
    takeLatest(types.DELETE_SUBMISSION, deleteSubmission),
  ]);
}

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const clientCredentials = {
  apiKey: "AIzaSyBVjoP5nsF_cowukZNNxZTfm4eijD8wJVY",
  authDomain: "ais-sites.firebaseapp.com",
  projectId: "ais-sites",
  storageBucket: "ais-sites.appspot.com",
  messagingSenderId: "856632944560",
  appId: "1:856632944560:web:a6183ac52e4b6688b11875"
};

const firebaseApp = initializeApp(clientCredentials);

export const auth = getAuth(firebaseApp);
export const googleAuthProvider = new GoogleAuthProvider();

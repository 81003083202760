import styled from 'styled-components';
import { color, font } from 'shared/utils/styles';

export const StyledItem = styled.a`
  align-items: center;
  border-radius: 3px;
  color: inherit;
  cursor: ${props => (props.implemented ? 'pointer' : 'not-allowed')};
  display: flex;
  min-height: 41px;
  padding: 8px 12px;
  position: relative;
  text-decoration: none;
  user-select: none;

  &.active {
    background: ${color.backgroundLight};
    color: ${color.primary};
  }

  &:hover {
    background: ${props =>
      props.implemented ? color.backgroundLight : 'none'};

    .not-implemented {
      opacity: 1;
    }
  }

  .menu-icon {
    height: 20px;
    margin-right: 15px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .menu-name {
    padding-top: 2px;
    ${font.size(14.7)};
  }
`;

export const NotImplemented = styled.div`
  background: ${color.backgroundMedium};
  border-radius: 3px;
  color: ${color.textDark};
  display: inline-block;
  left: 40px;
  opacity: 0;
  padding: 5px 0px 5px 8px;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  width: 140px;
  ${font.bold};
  ${font.size(11.5)};
`;

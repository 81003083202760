import { createSelector } from 'reselect';
import { initialState } from './reducer';

const app = state => state.app || initialState;

export const getLoadStatus = () =>
  createSelector(app, store => store.loadStatus);

export const getUserToken = () => createSelector(app, store => store.userToken);
export const getUser = () => createSelector(app, store => store.currentUser);

export const getShowSidebar = () =>
  createSelector(app, store => store.showSidebar);

import { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { onIdTokenChanged } from 'firebase/auth';

import toast from 'components/toast';
import { auth } from 'configs/firebase';

import { AppReducer as reducer } from 'store/App/reducer';
import saga from 'store/App/sagas';
import { setLoadStatus, setUserToken } from 'store/App/actions';
import { getLoadStatus, getUser } from 'store/App/selectors';

import Routes from './Routes';
import NormalizeStyles from './NormalizeStyles';
import BaseStyles from './BaseStyles';
import Toast from './Toast';
import './fontStyles.css';

const App = () => {
  useInjectReducer({ key: 'app', reducer });
  useInjectSaga({ key: 'app', saga });

  const dispatch = useDispatch();
  const loadStatus = useSelector(getLoadStatus());
  const currentUser = useSelector(getUser());

  const handleFirebaseTokenChange = async user => {
    if (!user) {
      dispatch(setLoadStatus(false));
      return;
    }

    const token = await user.getIdToken();
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    dispatch(setUserToken(token));
  };

  const refreshFirebaseToken = async () => {
    if (auth.currentUser) {
      try {
        await auth.currentUser.getIdTokenResult(true);
      } catch (ex) {
        toast.error('Please reload your window to maintain the session!');
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, handleFirebaseTokenChange);

    // Firebase token will be refreshed every 10 mins
    const tokenIntervalId = setInterval(refreshFirebaseToken, 1000 * 60 * 10);

    return () => {
      unsubscribe();
      clearInterval(tokenIntervalId);
    };
  }, []);

  return (
    <>
      <NormalizeStyles />
      <BaseStyles />
      <Toast />
      <Routes loadStatus={loadStatus} currentUser={currentUser} />
    </>
  );
};

export default App;

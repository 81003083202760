import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Delete from 'components/Delete';
import Icon from 'components/Icon';
import { color } from 'shared/utils/styles';

import CircleIcon from 'static/icons/circle.svg';
import CircleCheckedIcon from 'static/icons/circle-checked.svg';
import DownloadIcon from 'static/icons/download.svg';
import UploadIcon from 'static/icons/upload.svg';
import EyeIcon from 'static/icons/eye.svg';
// import TrashIcon from 'static/icons/trash.svg';

import { StyledRow } from '../styles';
import { StyledBody, StyledTableItem } from './styles';

const TableBody = props => {
  const {
    data,
    onDownload,
    onDelete,
    onView,
    onSelect,
    selected,
    onTriggerMail,
  } = props;

  return (
    <StyledBody>
      {data.map(valueObj => {
        const isActive = selected.includes(valueObj._id);
        const iconBtnVariant = isActive ? 'secondary' : 'empty';
        const checkedColor = isActive ? color.success : color.textDark;

        return (
          <StyledRow
            key={valueObj._id}
            isActive={isActive}
            onClick={() => onSelect(valueObj._id)}
          >
            <StyledTableItem style={{ color: checkedColor }}>
              <Icon
                icon={isActive ? CircleCheckedIcon : CircleIcon}
                size={16}
              />
            </StyledTableItem>

            <StyledTableItem>
              <NavLink
                onClick={e => e.stopPropagation()}
                to={`submissions/${valueObj._id}`}
              >
                <p>{valueObj.name}</p>
              </NavLink>
            </StyledTableItem>

            <StyledTableItem>
              <p>{valueObj.email}</p>
            </StyledTableItem>

            <StyledTableItem>
              <p>{valueObj.date}</p>
            </StyledTableItem>

            <StyledTableItem>
              {new Date(valueObj.createdAt).toLocaleTimeString()}
            </StyledTableItem>

            <StyledTableItem align="right" padding="0px 10px">
              {valueObj.emailFailed && (
                <Button
                  icon={UploadIcon}
                  iconSize={16}
                  onClick={e => {
                    e.stopPropagation();
                    onTriggerMail(valueObj._id);
                  }}
                  variant={iconBtnVariant}
                  title="Retry failed email"
                />
              )}

              <Button
                icon={EyeIcon}
                iconSize={16}
                onClick={e => {
                  e.stopPropagation();
                  onView(valueObj._id);
                }}
                variant={iconBtnVariant}
                title="View attachments"
              />

              <Button
                icon={DownloadIcon}
                iconSize={16}
                onClick={e => {
                  e.stopPropagation();
                  onDownload(valueObj._id);
                }}
                variant={iconBtnVariant}
                title="Download attachments"
              />
              <Delete
                iconSize={20}
                onDelete={() => onDelete([valueObj._id])}
                type="item"
              />
            </StyledTableItem>
          </StyledRow>
        );
      })}
    </StyledBody>
  );
};

TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onTriggerMail: PropTypes.func.isRequired,
  selected: PropTypes.array,
};
export default TableBody;

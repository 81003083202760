import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { color, sizes, zIndexValues } from 'shared/utils/styles';

import { showSidebar } from 'store/App/actions';
import { getShowSidebar } from 'store/App/selectors';

import Button from 'components/Button';
import MenuIcon from 'static/icons/menu.svg';

const Navbar = () => {
  const dispatch = useDispatch();
  const sidebarVisibility = useSelector(getShowSidebar());

  const toggleSidebar = () => {
    dispatch(showSidebar(!sidebarVisibility));
  };

  return (
    <NavbarWrapper>
      <Button
        className="sidebar-toggler"
        icon={MenuIcon}
        onClick={toggleSidebar}
        variant="primary"
      />
    </NavbarWrapper>
  );
};

export default Navbar;

const NavbarWrapper = styled.div`
  background-color: ${color.backgroundDarkPrimary};

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;

  height: 100vh;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: all 0.1s ease 0s;
  transform: translateZ(0px);
  width: ${sizes.appNavBarLeftWidth}px;
  z-index: ${zIndexValues.navLeft};

  @media (min-width: 1000px) {
    .sidebar-toggler {
      display: none;
    }
  }
`;

import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendPasswordResetEmail } from 'firebase/auth';

import Button from 'components/Button';
import Form from 'components/Form';
import toast from 'components/toast';

import { auth } from 'configs/firebase';

import { resetSchema } from './validations';
import { AuthWrapper } from './styles';

const Auth = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(resetSchema, { stripUnknown: true }) });

  const onSubmit = async data => {
    try {
      await sendPasswordResetEmail(auth, data.email);
      toast.success('Please check your mail!');
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  return (
    <AuthWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field.Input
          control={control}
          defaultValue=""
          name="email"
          label="Email address"
          error={errors.email?.message}
          type="email"
        />

        <Button type="submit" style={{ marginTop: '25px' }} variant="primary">
          Submit
        </Button>

        <div style={{ marginTop: '25px' }}>
          <Link to="/auth/signin">Login here</Link>
        </div>
      </Form>
    </AuthWrapper>
  );
};

export default Auth;

import React, { forwardRef } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import Input from 'components/Input';

import { StyledField, FieldLabel, FieldTip, FieldError } from './styles';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelStyles: PropTypes.object,
  tip: PropTypes.string,
  error: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  label: undefined,
  tip: undefined,
  error: undefined,
};

const generateField = FormComponent => {
  const FieldComponent = forwardRef(
    ({ className, label, tip, error, labelStyles, ...otherProps }, ref) => {
      const fieldId = uniqueId('form-field-');

      return (
        <StyledField className={className} hasLabel={!!label}>
          {label && (
            <FieldLabel style={labelStyles} htmlFor={fieldId}>
              {label}
            </FieldLabel>
          )}
          <FormComponent
            id={fieldId}
            invalid={!!error}
            ref={ref}
            {...otherProps}
          />
          {tip && <FieldTip>{tip}</FieldTip>}
          {error && <FieldError>{error}</FieldError>}
        </StyledField>
      );
    },
  );

  FieldComponent.propTypes = propTypes;
  FieldComponent.defaultProps = defaultProps;

  return FieldComponent;
};

export default {
  Input: generateField(Input),
};

import styled from 'styled-components';

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

// index.jsx
export const LayoutWrapper = styled.div`
  padding: 20px 30px 35px;
`;

// Header.jsx
export const HeaderWrapper = styled(FlexWrapper)``;

export const HeaderActions = styled(FlexWrapper)`
  & > * {
    &:not(:first-child) {
      margin-left: 5px;
    }
  }
`;

export const ContentWrapper = styled.div`
  margin: 20px 0px 0px;
`;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyledInput, InputElement, StyledIcon } from './styles';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  wrapperStyles: PropTypes.object,
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  invalid: false,
  onChange: () => {},
};

const Input = forwardRef(
  ({ icon, className, onChange, wrapperStyles, ...inputProps }, ref) => (
    <StyledInput style={wrapperStyles} className={className}>
      {icon && <StyledIcon icon={icon} size={15} />}
      <InputElement
        {...inputProps}
        onChange={onChange}
        hasIcon={!!icon}
        ref={ref}
      />
    </StyledInput>
  ),
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledToolbar = styled.div`
  align-items: center;
  // background: white;
  display: flex;
  height: ${props => (props.isActive ? '50px' : '0px')};
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 0px 15px;
  transition: all 0.25s ease;

  .action-btns {
    align-items: center;
    display: flex;
  }
`;

export const LOAD_STATUS = 'ADMIN/SUBMISSIONS/LOAD_STATUS';

export const FETCH_SUBMISSIONS_COUNT =
  'ADMIN/SUBMISSIONS/FETCH_SUBMISSIONS_COUNT';
export const RECEIVED_SUBMISSIONS_COUNT =
  'ADMIN/SUBMISSIONS/RECEIVED_SUBMISSIONS_COUNT';

export const FETCH_SUBMISSIONS = 'ADMIN/SUBMISSIONS/FETCH_SUBMISSIONS';
export const RECEIVED_SUBMISSIONS = 'ADMIN/SUBMISSIONS/RECEIVED_SUBMISSIONS';

export const SET_ITEMS_PER_PAGE = 'ADMIN/SUBMISSIONS/SET_ITEMS_PER_PAGE';

export const TRIGGER_MAIL = 'ADMIN/SUBMISSIONS/TRIGGER_MAIL';
export const TRIGGERED_MAIL = 'ADMIN/SUBMISSIONS/TRIGGERED_MAIL';

export const DELETE_SUBMISSION = 'ADMIN/SUBMISSIONS/DELETE_SUBMISSION';
export const DELETED_SUBMISSION = 'ADMIN/SUBMISSIONS/DELETED_SUBMISSION';

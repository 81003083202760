import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactSVG } from "react-svg";
import {
  signInWithEmailAndPassword,
  // signInWithPopup,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import Button from "components/Button";
import Form from "components/Form";
import toast from "components/toast";

import {
  auth,
  // googleAuthProvider,
} from "configs/firebase";

import Logo from "static/icons/logo.svg";
import { loginSchema } from "./validations";
import { AuthWrapper } from "./styles";

const Auth = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({ resolver: yupResolver(loginSchema, { stripUnknown: true }) });

  const email = watch("email");
  const password = watch("password");

  const onSubmit = async (data) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  const onSignup = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast.success(
        "You have signed up successfully and your login will be approved shortly.."
      );
    } catch (ex) {
      toast.error(ex.message);
    }
  };

  // const onGoogleSignIn = async () => {
  //   try {
  //     await signInWithPopup(auth, googleAuthProvider);
  //   } catch (ex) {
  //     toast.error(ex.message);
  //   }
  // };

  return (
    <AuthWrapper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={Logo} style={{width:150, " & *": { width: '75px' } }} />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field.Input
          control={control}
          defaultValue=""
          name="email"
          label="Email address"
          error={errors.email?.message}
          type="email"
        />

        <Form.Field.Input
          control={control}
          defaultValue=""
          name="password"
          label="Password"
          error={errors.password?.message}
          type="password"
        />

        <Button type="submit" style={{ marginTop: "25px" }} variant="primary">
          Sign In
        </Button>

        {/* <Button
          type="button"
          onClick={onGoogleSignIn}
          style={{ margin: '25px 15px 0px' }}
        >
          Sign in with Google
        </Button> */}

        <Button
          type="button"
          onClick={onSignup}
          style={{ marginTop: "25px", marginLeft: "15px" }}
          variant="primary"
        >
          Sign up
        </Button>

        <div style={{ marginTop: "25px" }}>
          <Link to="/auth/reset">Forgot password?</Link>
        </div>
      </Form>
    </AuthWrapper>
  );
};

export default Auth;

import PropTypes from 'prop-types';

import Header from './Header';
import { ContentWrapper, LayoutWrapper } from './styles';

const propTypes = {
  children: PropTypes.any,
  modalClose: PropTypes.func,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
  title: PropTypes.string,
};

const UpdateLayout = ({
  children,
  modalClose,
  showDelete,
  title,
  onDelete,
}) => (
  <LayoutWrapper>
    <Header
      modalClose={modalClose}
      onDelete={onDelete}
      showDelete={showDelete}
      title={title}
    />
    <ContentWrapper>{children}</ContentWrapper>
  </LayoutWrapper>
);

UpdateLayout.propTypes = propTypes;

export default UpdateLayout;

import produce from 'immer';

import * as types from './constants';

export const initialState = {
  loadStatus: true,
  currentUser: null,
  userToken: null,
  showSidebar: false,
};

export const AppReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOAD_STATUS:
        draft.loadStatus = action.payload;
        break;

      case types.SET_USER:
        draft.currentUser = action.payload;
        break;

      case types.SET_USER_TOKEN:
        draft.userToken = action.payload;
        break;

      case types.SHOW_SIDEBAR:
        draft.showSidebar = action.payload;
        break;
    }
  });
